import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import may_1 from '../Assets/may/1.jpg'
import may_2 from '../Assets/may/2.jpg'
import may_3 from '../Assets/may/3.jpg'
import may_4 from '../Assets/may/4.jpg'
import may_5 from '../Assets/may/5.jpg'
import may_6 from '../Assets/may/6.jpg'
import may_7 from '../Assets/may/7.jpg'
import may_8 from '../Assets/may/8.jpg'
import may_9 from '../Assets/may/9.jpg'
import may_10 from '../Assets/may/10.jpg'
import may_11 from '../Assets/may/11.jpg'

import may_12 from '../Assets/may/12.jpg'
import may_13 from '../Assets/may/13.jpg'
import may_14 from '../Assets/may/14.jpg'
import may_15 from '../Assets/may/15.jpg'


import may_16 from '../Assets/may/16.jpg'
import may_17 from '../Assets/may/17.jpg'
import may_18 from '../Assets/may/18.jpg'

import may_19 from '../Assets/may/19.jpg'
import may_20 from '../Assets/may/20.jpg'
import may_21 from '../Assets/may/21.jpg'
import may_22 from '../Assets/may/22.jpg'

import may_23 from '../Assets/may/23.jpg'
import may_24 from '../Assets/may/24.jpg'
import may_25 from '../Assets/may/25.jpg'

import may_26 from '../Assets/may/26.jpg'
import may_27 from '../Assets/may/27.jpg'
import may_28 from '../Assets/may/28.jpg'

import may_29 from '../Assets/may/29.jpg'
import may_30 from '../Assets/may/30.jpg'
import may_31 from '../Assets/may/31.jpg'

import may_32 from '../Assets/may/32.jpg'
import may_33 from '../Assets/may/33.jpg'
import may_34 from '../Assets/may/34.jpg'

import may_35 from '../Assets/may/35.jpg'
import may_36 from '../Assets/may/36.jpg'
import may_37 from '../Assets/may/37.jpg'
import may_38 from '../Assets/may/38.jpg'

import may_39 from '../Assets/may/39.jpg'
import may_40 from '../Assets/may/40.jpg'
import may_41 from '../Assets/may/41.jpg'
import may_42 from '../Assets/may/42.jpg'

import may_43 from '../Assets/may/43.jpg'
import may_44 from '../Assets/may/44.jpg'
import may_45 from '../Assets/may/45.jpg'

import may_46 from '../Assets/may/46.jpg'
import may_47 from '../Assets/may/47.jpg'
import may_48 from '../Assets/may/48.jpg'
import may_49 from '../Assets/may/49.jpg'
import may_50 from '../Assets/may/50.jpg'
import may_51 from '../Assets/may/51.jpg'
import may_52 from '../Assets/may/52.jpg'
import may_53 from '../Assets/may/53.jpg'
import may_54 from '../Assets/may/54.jpg'
import may_55 from '../Assets/may/55.jpg'
import may_56 from '../Assets/may/56.jpg'

import may_57 from '../Assets/may/57.jpg'
import may_58 from '../Assets/may/58.jpg'
import may_59 from '../Assets/may/59.jpg'
import may_60 from '../Assets/may/60.jpg'
import may_61 from '../Assets/may/61.jpg'
import may_62 from '../Assets/may/62.jpg'
import may_63 from '../Assets/may/63.jpg'
import may_64 from '../Assets/may/64.jpg'

import may_65 from '../Assets/may/65.jpg'
import may_66 from '../Assets/may/66.jpg'
import may_67 from '../Assets/may/67.jpg'
import may_68 from '../Assets/may/68.jpg'
import may_69 from '../Assets/may/69.jpg'
import may_70 from '../Assets/may/70.jpg'
import may_71 from '../Assets/may/71.jpg'

import may_72 from '../Assets/may/72.jpg'
import may_73 from '../Assets/may/73.jpg'
import may_74 from '../Assets/may/74.jpg'
import may_75 from '../Assets/may/75.jpg'
import may_76 from '../Assets/may/76.jpg'
import may_77 from '../Assets/may/77.jpg'
import may_78 from '../Assets/may/78.jpg'
import may_79 from '../Assets/may/79.jpg'
import may_80 from '../Assets/may/80.jpg'
import may_81 from '../Assets/may/81.jpg'
import may_82 from '../Assets/may/82.jpg'



const October = () => {
  const images = [
    { img: may_1 },
    { img: may_2 },
    { img: may_3 },
    { img: may_4 },
    { img: may_5 },
    { img: may_6 },
    { img: may_7 },
    { img: may_8 },
    { img: may_9 },
    { img: may_10 },

    { img: may_11 },
    { img: may_12 },
    { img: may_13 },
    { img: may_14 },
    { img: may_15 },
    { img: may_16 },
    { img: may_17 },
    { img: may_18 },
    { img: may_19 },
    { img: may_20 },

    { img: may_21 },
    { img: may_22 },
    { img: may_23 },
    { img: may_24 },
    { img: may_25 },
    { img: may_26 },
    { img: may_27 },
    { img: may_28 },
    { img: may_29 },
    { img: may_30 },

    { img: may_31 },
    { img: may_32 },
    { img: may_33},
    { img: may_34 },
    { img: may_35 },
    { img: may_36 },
    { img: may_37 },
    { img: may_38 },
    { img: may_39 },
    { img: may_40 },

    { img: may_41 },
    { img: may_42 },
    { img: may_43 },
    { img: may_44 },
    { img: may_45 },
    { img: may_46 },
    { img: may_47 },
    { img: may_48 },
    { img: may_49 },
    { img: may_50 },

    { img: may_51 },
    { img: may_52 },
    { img: may_53 },
    { img: may_54 },
    { img: may_55 },
    { img: may_56 },
    { img: may_57 },
    { img: may_58 },
    { img: may_59 },
    { img: may_60 },

    { img: may_61 },
    { img: may_62 },
    { img: may_63 },
    { img: may_64 },
    { img: may_65 },
    { img: may_66 },
    { img: may_67 },
    { img: may_68 },
    { img: may_69 },
    { img: may_70 },

    { img: may_71 },
    { img: may_72 },
    { img: may_73 },
    { img: may_74 },
    { img: may_75 },
    { img: may_76 },
    { img: may_77 },
    { img: may_78 },
    { img: may_79 },
    { img: may_80 },

    { img: may_81 },
    { img: may_82 },
,
  ];
  return (
    <div>
      <Header />
      <h1 className="heading">
        <u>October 2021</u>
      </h1>

      <div className="container-fluid">
        <div className="row mt-4">
          {images.map((item, index) => {
            return (
              <div className="item col-sm-6 col-md-3 mb-3">
                <a href={item.img} className="fancybox" data-fancybox="gallery1">
                  <img src={item.img} width="100%" height="100%" />
                </a>
              </div>
            );
          })}
        </div>
      </div>

      <div id="fb-root"></div>
      <Footer />
    </div>
  );
};

export default October;
