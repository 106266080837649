import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import banner from '../Assets/Home Page-Banner (Landscape).png'
import Annapurna_image from '../Assets/Annapurna-image.jpeg'
import home_home_2 from '../Assets/home-home-2.jpg'
import arogyam_banner from '../Assets/arogyam-banner.jpg'

const Home = () => {
  return (
    <div>
      <Header />
      <div
        id="carouselExampleControls"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active d-none d-md-block">
            <img
              src={banner}
              className="d-block w-100"
              alt="..."
            />
          </div>
        </div>
      </div>
      <div className="d-block d-md-none">
        <img
       src={banner}
          className="d-block w-100"
          alt="..."
        />
      </div>
      <div className="gap-1"></div>
      <div className="container-fluid" id="start">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 text-center">
            <h1 className="display-6">
              <strong>AKANKSHA ARADHYA FOUNDATION</strong>
            </h1>
          </div>
        </div>
      </div>

      <div className="gap-1"></div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-6 col-md-12 col-sm-12">
            <p align="justify">
              <strong>AKANKSHA ARADHYA FOUNDATION (AAF)</strong> is an
              initiative started in 2020 to bring out an awareness in the
              society about the menaces in the Health & Educational Industry.
              AAF is an NGO which will take care of abandoned girl children and
              treat them as our own daughters so as to give them all
              opportunities to bloom and grow, creating a wonderful society to
              live for all.
            </p>
          </div>
          <div className="col-xl-6 col-md-12 col-sm-12 text-center">
            <img
              className="img-fluid"
              src={Annapurna_image}
              alt=""
              style={{height: "300px"}}
            />
          </div>
        </div>
      </div>

      <div className="gap-1"></div>
      <div className="gap-1"></div>
      <div className="container-fluid" id="start">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 text-center">
            <h1 className="display-6">
              <u>GIRL CHILD ADOPTION</u>
            </h1>
          </div>
        </div>
      </div>

      <div className="gap-1"></div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-6 col-md-12 col-sm-12 text-center">
            <img
              className="img-fluid"
              src={home_home_2}
              alt=""
            />
          </div>
          <div className="col-xl-6 col-md-12 col-sm-12">
            <p align="justify">
              It is said that when God made man, he thought his creation was
              incomplete, so he made women, a complete soul, to guide, motivate,
              love, take care of man so that he can be complete. A woman has
              several roles - mother, sister, friend, wife, partner and
              daughter, and she plays these roles very easily, but unfortunately
              we treat them very badly.
            </p>
          </div>
        </div>
      </div>

      <div className="gap-1"></div>
      <div className="gap-1"></div>

      <div className="container-fluid" id="start">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 text-center">
            <h1 className="display-6">
              <u>WHOLISTIC WELLNESS</u>
            </h1>
          </div>
        </div>
      </div>
      <div className="gap-1"></div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-6 col-md-12 col-sm-12">
            <p align="justify">
              <strong>Akanksha Aradhya Foundation</strong> believes that good
              length is not just a body level management and diseases are not
              just because of body level malfunctioning but its deep rooted in
              our emotional behaviour patterns. Diseases are manifested first at
              the emotional level and consistent emotional attacks create
              permanent changes which in turn create hormonal/metabolic
              imbalance which start effecting respective organs when organs
              start malfunctioning the symptoms start surfacing.
            </p>
          </div>
          <div className="col-xl-6 col-md-12 col-sm-12 text-center">
            <img
              className="img-fluid"
              src={arogyam_banner}
              alt=""
              style={{height: "300px"}}
            />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
