import React from 'react'
import Header from './Header'
import Footer from './Footer'
import banner_4 from '../Assets/Banner-4 PNG.png'
import banner_6 from '../Assets/Ek bakwas love story Poster ticket 6.jpg'

const EkBakwaasStory = () => {
  return (
    <div>
        <Header/>
        <form action="register.php" method="POST">
    <div className="container-fluid bg-light">
        <div className="main-banner w-100">
            <img src={banner_4} alt="main banner" className="img-fluid pb-3 w-100" style={{objectFit: "cover"}}/>
        </div>
        <div className="bg-white p-5">
            <div className="section2 mt-5">
                <div className="row">
                    <div className="col-sm-12 col-md-6 text-center">
                        <img src={banner_6} alt=""
                            className="img-fluid rounded shadow" style={{height: "400px"}}/>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <h3 className="text-center mb-3">Ek Bakwaas Love Story @...</h3>
                        <p align="justify">Sartrac Global Invites you all to their first theatre production in association with EK Junoon. The name of the play is Ek Bakwas Love Story @... which is a comedy satire on what all is going on in the name of love. Most of the actors are amateurs trained under the able guidance of Director Anamika Gautam who is the founder of Ekk Junoon. Do join us on the evening of 3rd April and encourage us.</p>
                        <div className="row">
                            <div className="col-6">
                                <p><span className="fw-bold">Date:</span> April, 03 2022</p>
                            </div>
                            <div className="col-6">
                                <p><span className="fw-bold">Time:</span> 06:30pm - 08:30pm</p>
                            </div>
                        </div>
                        <p><span className="fw-bold">Price:</span> ₹300/-</p>
                        <p><span className="fw-bold">Address:</span> The Little Theatre Group Auditorium, 1, Copernicus
                            Marg, Mandi House, Opp. Doordarshan Bhavan, near Connaught Place, New Delhi, Delhi, 110001
                        </p>
                        <h5 className="text-danger">For any query related to Booking, Contact at:</h5>
                        <p><span className="fw-bold">Email:</span> <a style={{textDecoration: "none", color:"black"}} href="/cdn-cgi/l/email-protection#71511003041f31101a101f1a02191010031015190810171e041f151005181e1f5f121e1c"><span className="__cf_email__" data-cfemail="ceafbcbba08eafa5afa0a5bda6afafbcafaaa6b7afa8a1bba0aaafbaa7a1a0e0ada1a3">[email&#160;protected]</span></a>
                        </p>
                        <p><span className="fw-bold">Mobile:</span> <a style={{textDecoration: "none", color:"black"}}
                                href="tel: +91 88822 12649">+91 88822 12649</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div className="container mt-5 mb-5">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.217645287428!2d77.22912731462122!3d28.623238391253313!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd2cd353fd57%3A0x27462f5715d48c3e!2sLittle%20Theatre%20Group%20(LTG)%20Auditorium!5e0!3m2!1sen!2sin!4v1647080920575!5m2!1sen!2sin"
                style={{border:"0", width: "100%", height: "400px"}} allowfullscreen="" loading="lazy"></iframe>
        </div>
    </div>
</form>


    <div id="fb-root"></div>
        <Footer/>
    </div>
  )
}

export default EkBakwaasStory