import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import january_1 from "../Assets/january/1.jpg";
import january_2 from "../Assets/january/2.jpg";
import january_3 from "../Assets/january/3.jpg";
import january_4 from "../Assets/january/4.jpg";
import january_5 from "../Assets/january/5.jpg";
import january_6 from "../Assets/january/6.jpg";
import january_7 from "../Assets/january/7.jpg";
import january_8 from "../Assets/january/8.jpg";
import january_9 from "../Assets/january/9.jpg";
import january_10 from "../Assets/january/10.jpg";
import january_11 from "../Assets/january/11.jpg";
import january_12 from "../Assets/january/12.jpg";

const January = () => {
  const images = [
    {
      img: january_1,
    },
    {
      img: january_2,
    },
    {
      img: january_3,
    },
    {
      img: january_4,
    },
    {
      img: january_5,
    },
    {
      img: january_6,
    },
    {
      img: january_7,
    },
    {
      img: january_8,
    },
    {
      img: january_9,
    },
    {
      img: january_10,
    },
    {
      img: january_11,
    },
    {
      img: january_12,
    },
  ];

  return (
    <div>
      <Header />
      <h1 className="heading">
        <u>January 2021</u>
      </h1>

      <div className="container-fluid">
        <div className="row mt-4">
          {images.map((item, index) => {
            return (
              <div key={index} className="item col-sm-6 col-md-3 mb-3">
                <a
                  href={item.img}
                  className="fancybox"
                  data-fancybox="gallery1"
                >
                  <img src={item.img} width="100%" height="100%" />
                </a>
              </div>
            );
          })}
          {/* <div className="item col-sm-6 col-md-3 mb-3">
            <a href={january_1} className="fancybox" data-fancybox="gallery1">
              <img src={january_1} width="100%" height="100%" />
            </a>
          </div>
          <div className="item col-sm-6 col-md-3 mb-3">
            <a href={january_2} className="fancybox" data-fancybox="gallery1">
              <img src={january_2} width="100%" height="100%" />
            </a>
          </div>
          <div className="item col-sm-6 col-md-3 mb-3">
            <a href={january_3} className="fancybox" data-fancybox="gallery1">
              <img src={january_3} width="100%" height="100%" />
            </a>
          </div>
          <div className="item col-sm-6 col-md-3 mb-3">
            <a href={january_4} className="fancybox" data-fancybox="gallery1">
              <img src={january_4} width="100%" height="100%" />
            </a>
          </div>
          <div className="item col-sm-6 col-md-3 mb-3">
            <a href={january_5} className="fancybox" data-fancybox="gallery1">
              <img src={january_5} width="100%" height="100%" />
            </a>
          </div>
          <div className="item col-sm-6 col-md-3 mb-3">
            <a href={january_6} className="fancybox" data-fancybox="gallery1">
              <img src={january_6} width="100%" height="100%" />
            </a>
          </div>
          <div className="item col-sm-6 col-md-3 mb-3">
            <a href={january_7} className="fancybox" data-fancybox="gallery1">
              <img src={january_7} width="100%" height="100%" />
            </a>
          </div>
          <div className="item col-sm-6 col-md-3 mb-3">
            <a href={january_8} className="fancybox" data-fancybox="gallery1">
              <img src={january_8} width="100%" height="100%" />
            </a>
          </div>
          <div className="item col-sm-6 col-md-3 mb-3">
            <a href={january_9} className="fancybox" data-fancybox="gallery1">
              <img src={january_9} width="100%" height="100%" />
            </a>
          </div>
          <div className="item col-sm-6 col-md-3 mb-3">
            <a href={january_10} className="fancybox" data-fancybox="gallery1">
              <img src={january_10} width="100%" height="100%" />
            </a>
          </div>
          <div className="item col-sm-6 col-md-3 mb-3">
            <a href={january_11} className="fancybox" data-fancybox="gallery1">
              <img src={january_11} width="100%" height="100%" />
            </a>
          </div>
          <div className="item col-sm-6 col-md-3 mb-3">
            <a href={january_12} className="fancybox" data-fancybox="gallery1">
              <img src={january_12} width="100%" height="100%" />
            </a>
          </div> */}
        </div>
      </div>

      <div id="fb-root"></div>
      <Footer />
    </div>
  );
};

export default January;
