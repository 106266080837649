import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./compoenets/Home";
import AboutUS from "./compoenets/AboutUS";
import Gurukul from "./compoenets/Gurukul";
import Arogyam from "./compoenets/Arogyam";
import Annapurna from "./compoenets/Annapurna";
import Palna from "./compoenets/Palna";
import UpComingEvent from "./compoenets/UpComingEvent";
import PastEvent from "./compoenets/PastEvent";
import EkBakwaasStory from "./compoenets/EkBakwaasStory";
import News from "./compoenets/News";
import Gallery from "./compoenets/Gallery";
import RighttoFood from "./compoenets/RighttoFood";
import Contact from "./compoenets/Contact";
import January from "./compoenets/January";
import Febuary from "./compoenets/Febuary";
import March from "./compoenets/March";
import April from "./compoenets/April";
import May from "./compoenets/May";
import June from "./compoenets/June";
import July from "./compoenets/July";
import August from "./compoenets/August";
import September from "./compoenets/September";
import October from "./compoenets/October";
import November from "./compoenets/November";
import TheaterList from "./compoenets/TheaterList";
import Theater_Ek_Bakwaas_Love_Story from "./compoenets/Theater_Ek_Bakwaas_Love_Story";
import Theater_Ohfish_Office from "./compoenets/Theater_Ohfish_Office";
import Donate from "./compoenets/Donate";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUS/>}/>
          <Route path="/gurukul" element={<Gurukul/>}/>
          <Route path='/arogyam' element={<Arogyam/>}/>
          <Route path="/annapurna" element={<Annapurna/>}/>
          <Route path="/palna" element={<Palna/>}/>
          <Route path="/upcomingevent" element={<UpComingEvent/>}/>
          <Route path="/past-events" element={<PastEvent/>}/>
          <Route path="/ek_bakbaas_story" element={<EkBakwaasStory/>}/>
          <Route path="/news" element={<News/>}/>
          <Route path="/gallery" element={<Gallery/>}/>
          <Route path="/right_to_food" element={<RighttoFood/>}/>
          <Route path="/contact" element={<Contact/>}/>
          <Route path='/january' element={<January/>}/>
          <Route path="/february" element={<Febuary/>}/>
          <Route path="/march" element={<March/>}/>
          <Route path="/april" element={<April/>}/>
          <Route path="/may" element={<May/>}/>
          <Route path="/june" element={<June/>}/>
          <Route path="/july" element={<July/>}/>
          <Route path="/august" element={<August/>}/>
          <Route path="/september" element={<September/>}/>
          <Route path="/october" element={<October/>}/>
          <Route path="/november" element={<November/>}/>
          <Route path="/theater_list" element={<TheaterList/>}/>
          <Route path="/theater_ek_bakwaas_love_story" element={<Theater_Ek_Bakwaas_Love_Story/>}/>
          <Route path="/theater_ohfish_office" element={<Theater_Ohfish_Office/>}/>
          <Route path="/donate" element={<Donate/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
