import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import FAB_4586 from "../Assets/theater/ek bakwaas love story/FAB_4586.JPG";
import FAB_4587 from "../Assets/theater/ek bakwaas love story/FAB_4587.JPG";
import FAB_4588 from "../Assets/theater/ek bakwaas love story/FAB_4588.JPG";
import FAB_4589 from "../Assets/theater/ek bakwaas love story/FAB_4589.JPG";
import FAB_4590 from "../Assets/theater/ek bakwaas love story/FAB_4590.jpg";
import FAB_4655 from "../Assets/theater/ek bakwaas love story/FAB_4655.JPG";
import FAB_4656 from "../Assets/theater/ek bakwaas love story/FAB_4656.JPG";
import FAB_4661 from "../Assets/theater/ek bakwaas love story/FAB_4661.JPG";
import FAB_4694 from "../Assets/theater/ek bakwaas love story/FAB_4694.JPG";
import FAB_4707 from "../Assets/theater/ek bakwaas love story/FAB_4707.JPG";
import FAB_4720 from "../Assets/theater/ek bakwaas love story/FAB_4720.JPG";

import a from "../Assets/theater/ek bakwaas love story/FAB_4724.JPG";
import b from "../Assets/theater/ek bakwaas love story/FAB_4724.JPG";
import c from "../Assets/theater/ek bakwaas love story/FAB_4728.JPG";
import d from "../Assets/theater/ek bakwaas love story/FAB_4729.JPG";
import e from "../Assets/theater/ek bakwaas love story/FAB_4730.JPG";
import f from "../Assets/theater/ek bakwaas love story/FAB_4748.JPG";
import g from "../Assets/theater/ek bakwaas love story/FAB_4755.JPG";
import h from "../Assets/theater/ek bakwaas love story/FAB_4757.JPG";
import i from "../Assets/theater/ek bakwaas love story/FAB_4763.JPG";
import j from "../Assets/theater/ek bakwaas love story/FAB_4767.JPG";
import k from "../Assets/theater/ek bakwaas love story/FAB_4768.JPG";
import l from "../Assets/theater/ek bakwaas love story/FAB_4770.JPG";
import m from "../Assets/theater/ek bakwaas love story/FAB_4773.JPG";
import n from "../Assets/theater/ek bakwaas love story/FAB_4777.JPG";
import o from "../Assets/theater/ek bakwaas love story/FAB_4780.JPG";
import p from "../Assets/theater/ek bakwaas love story/FAB_4785.JPG";
import q from "../Assets/theater/ek bakwaas love story/FAB_4788.JPG";
import r from "../Assets/theater/ek bakwaas love story/FAB_4798.JPG";
import s from "../Assets/theater/ek bakwaas love story/FAB_4807.JPG";
import t from "../Assets/theater/ek bakwaas love story/FAB_4804.JPG";
import u from "../Assets/theater/ek bakwaas love story/FAB_4811.JPG";
import v from "../Assets/theater/ek bakwaas love story/FAB_4817.JPG";
import w from "../Assets/theater/ek bakwaas love story/FAB_4826.JPG";
import x from "../Assets/theater/ek bakwaas love story/FAB_4844.JPG";
import y from "../Assets/theater/ek bakwaas love story/FAB_4847.JPG";
import z from "../Assets/theater/ek bakwaas love story/FAB_4853.JPG";
import aa from "../Assets/theater/ek bakwaas love story/FAB_4861.JPG";
import ab from "../Assets/theater/ek bakwaas love story/FAB_4865.JPG";
import ac from "../Assets/theater/ek bakwaas love story/FAB_4883.JPG";
import ad from "../Assets/theater/ek bakwaas love story/FAB_4886.JPG";
import ae from "../Assets/theater/ek bakwaas love story/FAB_4888.JPG";
import af from "../Assets/theater/ek bakwaas love story/FAB_4890.JPG";
import ag from "../Assets/theater/ek bakwaas love story/FAB_4901.JPG";
import ah from "../Assets/theater/ek bakwaas love story/FAB_4909.JPG";
import ai from "../Assets/theater/ek bakwaas love story/FAB_4912.JPG";
import aj from "../Assets/theater/ek bakwaas love story/FAB_4917.JPG";
import ak from "../Assets/theater/ek bakwaas love story/FAB_4921.JPG";
import al from "../Assets/theater/ek bakwaas love story/FAB_4932.JPG";
import am from "../Assets/theater/ek bakwaas love story/FAB_4941.JPG";
import an from "../Assets/theater/ek bakwaas love story/FAB_4957.JPG";
import ao from "../Assets/theater/ek bakwaas love story/FAB_4960.JPG";
import ap from "../Assets/theater/ek bakwaas love story/FAB_4980.JPG";
import aq from "../Assets/theater/ek bakwaas love story/FAB_4982.JPG";
import ar from "../Assets/theater/ek bakwaas love story/FAB_4990.JPG";
import as from "../Assets/theater/ek bakwaas love story/FAB_4995.JPG";
import at from "../Assets/theater/ek bakwaas love story/FAB_5011.JPG";
import au from "../Assets/theater/ek bakwaas love story/FAB_5026.JPG";
import av from "../Assets/theater/ek bakwaas love story/FAB_5033.JPG";
import aw from "../Assets/theater/ek bakwaas love story/FAB_5035.JPG";
import ax from "../Assets/theater/ek bakwaas love story/FAB_5042.JPG";
import ay from "../Assets/theater/ek bakwaas love story/FAB_5046.JPG";
import az from "../Assets/theater/ek bakwaas love story/FAB_5060.JPG";
import ba from "../Assets/theater/ek bakwaas love story/FAB_5066.JPG";
import bb from "../Assets/theater/ek bakwaas love story/FAB_5071 (1).JPG";
import bc from "../Assets/theater/ek bakwaas love story/FAB_5076.JPG";
import bd from "../Assets/theater/ek bakwaas love story/FAB_5084.JPG";
import be from "../Assets/theater/ek bakwaas love story/FAB_5087.JPG";
import bf from "../Assets/theater/ek bakwaas love story/FAB_5098.JPG";
import bg from "../Assets/theater/ek bakwaas love story/FAB_5104.JPG";
import bh from "../Assets/theater/ek bakwaas love story/FAB_5109.JPG";
import bi from "../Assets/theater/ek bakwaas love story/FAB_5114.JPG";
import bj from "../Assets/theater/ek bakwaas love story/FAB_5181.JPG";
import bk from "../Assets/theater/ek bakwaas love story/FAB_5182.JPG";
import bl from "../Assets/theater/ek bakwaas love story/FAB_5198.JPG";
import bm from "../Assets/theater/ek bakwaas love story/FAB_5201.JPG";

const Theater_Ek_Bakwaas_Love_Story = () => {
  const images = [
    FAB_4586,
    FAB_4587,
    FAB_4586,
    FAB_4587,
    FAB_4588,
    FAB_4589,
    FAB_4590,
    FAB_4655,
    FAB_4656,
    FAB_4661,
    FAB_4694,
    FAB_4707,
    FAB_4720,
    a,
    b,
    c,
    d,
    e,
    f,
    g,
    h,
    i,
    j,
    k,
    l,
    m,
    n,
    o,
    p,
    q,
    r,
    s,
    t,
    u,
    v,
    w,
    x,
    y,
    z,
    aa,
    ab,
    ac,
    ad,
    ae,
    af,
    ag,
    ah,
    ai,
    aj,
    ak,
    al,
    am,
    an,
    ao,
    ap,
    aq,
    ar,
    as,
    at,
    au,
    av,
    aw,
    ax,
    ay,
    az,
    ba,
    bb,
    bc,
    bd,
    be,
    bf,
    bg,
    bh,
    bi,
    bj,
    bk,
    bl,
    bm,
  ];
  return (
    <div>
      <Header />
      <h1 className="heading">
        <u>Ek Bakwaas Love Story</u>
      </h1>

      <div className="container-fluid">
        <div className="row mt-4">
          {images.map((image, index) => (
            <div className="item col-sm-6 col-md-3 mb-3" key={index}>
              <a href={image} className="fancybox" data-fancybox="gallery1">
                <img
                  src={image}
                  width="100%"
                  className="img-fluid height_set"
                  alt={`Slide ${index + 1}`}
                />
              </a>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Theater_Ek_Bakwaas_Love_Story;
