import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import august_1 from "../Assets/august/1.jpg";
import august_2 from "../Assets/august/2.jpg";
import august_3 from "../Assets/august/3.jpg";
import august_4 from "../Assets/august/4.jpg";
import august_5 from "../Assets/august/5.jpg";
import august_6 from "../Assets/august/6.jpg";
import august_7 from "../Assets/august/7.jpg";
import august_8 from "../Assets/august/8.jpg";
import august_9 from "../Assets/august/9.jpg";
import august_10 from "../Assets/august/10.jpg";
import august_11 from "../Assets/august/11.jpg";

import august_12 from "../Assets/august/12.jpg";
import august_13 from "../Assets/august/13.jpg";
import august_14 from "../Assets/august/14.jpg";
import august_15 from "../Assets/august/15.jpg";

import august_16 from "../Assets/august/16.jpg";
import august_17 from "../Assets/august/17.jpg";

import august_18 from "../Assets/august/18.jpg";
import august_19 from "../Assets/august/19.jpg";
import august_20 from "../Assets/august/20.jpg";

const August = () => {
  const images = [
    { img: august_1 },
    { img: august_2 },
    { img: august_3 },
    { img: august_4 },
    { img: august_5 },
    { img: august_6 },
    { img: august_7 },
    { img: august_8 },
    { img: august_9 },
    { img: august_10 },

    { img: august_11 },
    { img: august_12 },
    { img: august_13 },
    { img: august_14 },
    { img: august_15 },
    { img: august_16 },
    { img: august_17 },

    { img: august_18 },
    { img: august_19 },
    { img: august_20 },

  ];
  return (
    <div>
      <Header />
      <h1 className="heading">
        <u>August 2021</u>
      </h1>

      <div className="container-fluid">
        <div className="row mt-4">
          {images.map((item, index) => {
            return (
              <div className="item col-sm-6 col-md-3 mb-3">
                <a href={item.img} className="fancybox" data-fancybox="gallery1">
                  <img src={item.img} width="100%" height="100%" />
                </a>
              </div>
            );
          })}
        </div>
      </div>

      <div id="fb-root"></div>
      <Footer />
    </div>
  );
};

export default August;
