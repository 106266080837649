import React from 'react'
import Header from './Header'
import Footer from './Footer'
import gurukul_banner from '../Assets/gurukul-banner.jpg'

const Gurukul = () => {
  return (
    <div><Header/>
     <div className="container">
        <h1 className="heading"><u>The Gurukul Initiative</u></h1>
    </div>
    <div className="gap-1"></div>
    <div className="container">
        <div className="row">
            <div className="container col-xl-6 col-md-12 col-sm-12">
                <div className="gurukul-image">
                    <img className="img-fluid" src={gurukul_banner} alt=""/>
                </div>
            </div>
            <div className="container col-xl-6 col-md-12 col-sm-12 mt-2">
                <div className="gurukul_paragraph">
                    <p align="justify">Education as a fundamental human right lies at the heart of UNESCO's mission and is enshrined in the Universal Declaration of Human Rights (1948) and many other <strong>international human rights instruments.</strong></p>
                    <p align="justify">The right to education is one of the key principles underpinning the <strong>Education 2030</strong> agenda and Sustainable Development Goal 4 (SDG4) adopted by the international community. SDG4 is rights based and seeks to ensure
                        the full enjoyment of the right to education as fundamental to achieving sustainable development. <strong>Yet millions of children and adults</strong> remain deprived of educational opportunities, many as result of social, cultural
                        and economic factors.</p>
                    <p align="justify"><strong>Akanksha Aradhya Foundation</strong> is committed to provide education to one and all. At AAF we believe that education is not just learning certain skills or career development but it is complete evolution of a human being
                        considering all aspects of life. We strive to educate students at every level the importance of soft skills, communication skills, positive attitude, training of mind power and channelizing the energy sources within to create a
                        better society. AAF aims at achieving these goals through setting up of Gurukuls across the globe and propagate these values through workshops, seminars, talk shows, trainings and books.</p>
                </div>
            </div>
        </div>
    </div>
    <Footer/></div>
  )
}

export default Gurukul