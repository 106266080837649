import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import contact from "../Assets/contact-collage.png";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    description: ""
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Form submitted with data:", formData);
  };

  return (
    <div>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <div className="container col-lg-6 col-sm-12 col-md-12">
            <img className="img-fluid" src={contact} alt="" />
          </div>
          <div className="container-fluid contact_container col-lg-6 col-sm-12 col-md-12">
            <div className="form_container">
              <div className="heading_contact">
                <h2>Contact us</h2>
              </div>

              <form className="contact_form" onSubmit={handleSubmit}>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="name" 
                    placeholder="Name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                  <label htmlFor="name">Name</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="email"
                    className="form-control"
                    id="email" 
                    placeholder="name@example.com"
                    value={formData.email}
                    onChange={handleChange} 
                  />
                  <label htmlFor="email">Email address</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="tel"
                    className="form-control"
                    id="phone"
                    placeholder="phone no."
                    value={formData.phone}
                    onChange={handleChange} 
                  />
                  <label htmlFor="phone">Phone no.</label>
                </div>
                <div className="form-floating mb-3">
                  <textarea
                    className="form-control"
                    placeholder="Leave a comment here"
                    id="description" 
                    style={{ height: "100px" }}
                    value={formData.description}
                    onChange={handleChange} 
                  ></textarea>
                  <label htmlFor="description">Description</label>
                </div>
                <div className="button_submit">
                  <button type="submit" className="submit_button">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div id="fb-root"></div>
      <Footer />
    </div>
  );
};

export default Contact;
