import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import march_1 from '../Assets/march/1.jpg'
import march_2 from '../Assets/march/2.jpg'
import march_3 from '../Assets/march/3.jpg'
import march_4 from '../Assets/march/4.jpg'
import march_5 from '../Assets/march/5.jpg'
import march_6 from '../Assets/march/6.jpg'
import march_7 from '../Assets/march/7.jpg'
import march_8 from '../Assets/march/8.jpg'
import march_9 from '../Assets/march/9.jpg'
import march_10 from '../Assets/march/10.jpg'
import march_11 from '../Assets/march/11.jpg'

import march_12 from '../Assets/march/12.jpg'
import march_13 from '../Assets/march/13.jpg'
import march_14 from '../Assets/march/14.jpg'
import march_15 from '../Assets/march/15.jpg'


import march_16 from '../Assets/march/16.jpg'
import march_17 from '../Assets/march/17.jpg'
import march_18 from '../Assets/march/18.jpg'

import march_19 from '../Assets/march/19.jpg'
import march_20 from '../Assets/march/20.jpg'
import march_21 from '../Assets/march/21.jpg'
import march_22 from '../Assets/march/22.jpg'

import march_23 from '../Assets/march/23.jpg'
import march_24 from '../Assets/march/24.jpg'
import march_25 from '../Assets/march/25.jpg'

import march_26 from '../Assets/march/26.jpg'
import march_27 from '../Assets/march/27.jpg'
import march_28 from '../Assets/march/28.jpg'

import march_29 from '../Assets/march/29.jpg'
import march_30 from '../Assets/march/30.jpg'
import march_31 from '../Assets/march/31.jpg'

import march_32 from '../Assets/march/32.jpg'
import march_33 from '../Assets/march/33.jpg'
import march_34 from '../Assets/march/34.jpg'

import march_35 from '../Assets/march/35.jpg'
import march_36 from '../Assets/march/36.jpg'
import march_37 from '../Assets/march/37.jpg'
import march_38 from '../Assets/march/38.jpg'

import march_39 from '../Assets/march/39.jpg'
import march_40 from '../Assets/march/40.jpg'
import march_41 from '../Assets/march/41.jpg'
import march_42 from '../Assets/march/42.jpg'

import march_43 from '../Assets/march/43.jpg'
import march_44 from '../Assets/march/44.jpg'
import march_45 from '../Assets/march/45.jpg'

import march_46 from '../Assets/march/46.jpg'
import march_47 from '../Assets/march/47.jpg'
import march_48 from '../Assets/march/48.jpg'
import march_49 from '../Assets/march/49.jpg'
import march_50 from '../Assets/march/50.jpg'
import march_51 from '../Assets/march/51.jpg'
import march_52 from '../Assets/march/52.jpg'
import march_53 from '../Assets/march/53.jpg'
import march_54 from '../Assets/march/54.jpg'
import march_55 from '../Assets/march/55.jpg'
import march_56 from '../Assets/march/56.jpg'


const September = () => {
  const images = [
    { img: march_1 },
    { img: march_2 },
    { img: march_3 },
    { img: march_4 },
    { img: march_5 },
    { img: march_6 },
    { img: march_7 },
    { img: march_8 },
    { img: march_9 },
    { img: march_10 },

    { img: march_11 },
    { img: march_12 },
    { img: march_13 },
    { img: march_14 },
    { img: march_15 },
    { img: march_16 },
    { img: march_17 },
    { img: march_18 },
    { img: march_19 },
    { img: march_20 },

    { img: march_21 },
    { img: march_22 },
    { img: march_23 },
    { img: march_24 },
    { img: march_25 },
    { img: march_26 },
    { img: march_27 },
    { img: march_28 },
    { img: march_29 },
    { img: march_30 },

    { img: march_31 },
    { img: march_32 },
    { img: march_33},
    { img: march_34 },
    { img: march_35 },
    { img: march_36 },
    { img: march_37 },
    { img: march_38 },
    { img: march_39 },
    { img: march_40 },

    { img: march_41 },
    { img: march_42 },
    { img: march_43 },
    { img: march_44 },
    { img: march_45 },
    { img: march_46 },
    { img: march_47 },
    { img: march_48 },
    { img: march_49 },
    { img: march_50 },

    { img: march_51 },
    { img: march_52 },
    { img: march_53 },
    { img: march_54 },
    { img: march_55 },
    { img: march_56 },
,
  ];
  return (
    <div>
      <Header />
      <h1 className="heading">
        <u>September 2021</u>
      </h1>

      <div className="container-fluid">
        <div className="row mt-4">
          {images.map((item, index) => {
            return (
              <div className="item col-sm-6 col-md-3 mb-3">
                <a href={item.img} className="fancybox" data-fancybox="gallery1">
                  <img src={item.img} width="100%" height="100%" />
                </a>
              </div>
            );
          })}
        </div>
      </div>

      <div id="fb-root"></div>
      <Footer />
    </div>
  );
};

export default September;
