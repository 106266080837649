import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import annapurana from '../Assets/Annapurna-image.png'
import teator from '../Assets/theater.webp'

const Gallery = () => {
  return (
    <div>
      <Header />
      <div className="container-fluid">
        <h1 className="gallery_heading">Gallery</h1>
      </div>
      <div className="container-fluid">
        <div className="row mt-4">
          <div className="card col-3 gallery-card border-0">
            <img
              src={annapurana}
              className="card-img-top"
              alt="..."
            />
            <div className="card-body">
              <h5 className="card-title">Right To Food</h5>
              <a href="/right_to_food" className=" btn btn-primary ">
                Full Album
              </a>
            </div>
          </div>
          <div className="card col-3 gallery-card border-0">
            <img
              src={teator}
              className="card-img-top"
              alt="... "
            />
            <div className="card-body">
              <h5 className="card-title">Theater</h5>
              <a href="/theater_list " className="btn btn-primary ">
                Full Album
              </a>
            </div>
          </div>
        </div>
      </div>

      <div id="fb-root"></div>
      <Footer />
    </div>
  );
};

export default Gallery;
