import React from 'react'
import Header from './Header'
import Footer from './Footer'
import banner from '../Assets/arogyam-banner.jpg'

const Arogyam = () => {
  return (
    <div>
        <Header/>
        <div className="container mt-2">
        <h1 className="heading"><u>The Arogyam Initiative</u></h1>
        <div className="gap-1"></div>
        <h2 className="heading">"Happiness is the manifestation of abundance."</h2>
    </div>
    <div className="gap-1"></div>
    <div className="container">
        <div className="row">
            <div className="container col-xl-6 col-md-12 col-sm-12">
        <div className="arogyam-image">
                <img className="img-fluid" src={banner} alt="" style={{height: "300px"}}/>
                </div>
            </div>
            <div className="container col-xl-6 col-md-12 col-sm-12">
            <div className="arogyam_paragraph">
                <p align="justify"><strong>Akanksha Aradhya Foundation</strong> believes that good health is not just a body level management and diseases are not just because of body level malfunctioning but its deep rooted in our emotional behaviour patterns. Diseases
                    are manifested first at the emotional level and consistent emotional attacks create permanent changes which in turn create hormonal/metabolic imbalance which start effecting respective organs and when organs start malfunctioning the
                    symptoms start surfacing. If we take good care of emotional challenges, we will be able to control the manifestation of diseases before symptoms occur. AAF aims at setting up cure centres across where patients are treated with various
                    sciences including Ayurveda, Allopathy and energy science keeping Yoga practice in prime focus.</p>
            </div>
            </div>
        </div>
    </div>

    <div id="fb-root"></div>
        <Footer/>
    </div>
  )
}

export default Arogyam