import React from 'react'
import Header from './Header'
import Footer from './Footer'
import banner from '../Assets/about-banner.jpeg'
import Annapurna_image from '../Assets/Annapurna-image.jpeg'

const AboutUS = () => {
  return (
    <div>
        <Header/>
        <div className="container-fluid">
        <div className="about_main">
            <img className="img-fluid w-100" src={banner} alt=""/>
        </div>
    </div>
    <div className="container">
        <div className="about_main2">
            <div className="row">
                <div className="col-xl-6 col-md-12 col-sm-12" style={{textAlign: "center"}}>
                    <img className="img-fluid" src={Annapurna_image} alt=""/>
                </div>

                <div className="col-xl-6 col-md-12 col-sm-12">
                    <h2 style={{textAlign:"center"}}><u>VISION</u></h2>
                    <p align="justify">To facilitate the evolution of a healthy, joyful society full of abundance and free from use of chemical medicine and negative energy.
                    </p>
                    <h2 style={{textAlign:"center"}}><u>MISSION</u></h2>
                    <p>To adopt baby girl child below 2 years, to give them our name, to treat them as our daughters and give them equal rights, to look after their education till they become independent and make them self reliant so that they can create
                        a change in the society in their respective fields.
                    </p>
                    <p align="justify">To create cure hospitals around the world where "Cure" is the main agenda.</p>
                    <p align="justify">To create schools around the world where instead of "career" students are taught "How to live a healthy life in abundance".</p>
                </div>
            </div>
        </div>
    </div>

    <div id="fb-root"></div>
        <Footer/>
    </div>
  )
}

export default AboutUS