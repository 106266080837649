import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import palna from '../Assets/palna-banner.jpg'

const Palna = () => {
  return (
    <div>
      <Header />
      <div className="container-fluid">
        <h1 className="heading">
          <u>The Palna Initiative</u>
        </h1>
      </div>
      <div className="gap-1"></div>
      <div className="container-fluid">
        <div className="palna-banner">
          <img
            className="img-fluid"
            src={palna}
            alt=""
          />
        </div>
      </div>

      <div id="fb-root"></div>

      <Footer />
    </div>
  );
};

export default Palna;
