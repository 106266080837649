import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import april_1 from '../Assets/april/1.jpg'
import april_2 from '../Assets/april/2.jpg'
import april_3 from '../Assets/april/3.jpg'
import april_4 from '../Assets/april/4.jpg'
import april_5 from '../Assets/april/5.jpg'
import april_6 from '../Assets/april/6.jpg'
import april_7 from '../Assets/april/7.jpg'
import april_8 from '../Assets/april/8.jpg'
import april_9 from '../Assets/april/9.jpg'
import april_10 from '../Assets/april/10.jpg'
import april_11 from '../Assets/april/11.jpg'

import april_12 from '../Assets/april/12.jpg'
import april_13 from '../Assets/april/13.jpg'
import april_14 from '../Assets/april/14.jpg'
import april_15 from '../Assets/april/15.jpg'


import april_16 from '../Assets/april/16.jpg'
import april_17 from '../Assets/april/17.jpg'
import april_18 from '../Assets/april/18.jpg'

import april_19 from '../Assets/april/19.jpg'
import april_20 from '../Assets/april/20.jpg'
import april_21 from '../Assets/april/21.jpg'
import april_22 from '../Assets/april/22.jpg'

import april_23 from '../Assets/april/23.jpg'
import april_24 from '../Assets/april/24.jpg'
import april_25 from '../Assets/april/25.jpg'

import april_26 from '../Assets/april/26.jpg'
import april_27 from '../Assets/april/27.jpg'
import april_28 from '../Assets/april/28.jpg'

import april_29 from '../Assets/april/29.jpg'
import april_30 from '../Assets/april/30.jpg'
import april_31 from '../Assets/april/31.jpg'

import april_32 from '../Assets/april/32.jpg'
import april_33 from '../Assets/april/33.jpg'
import april_34 from '../Assets/april/34.jpg'

import april_35 from '../Assets/april/35.jpg'
import april_36 from '../Assets/april/36.jpg'
import april_37 from '../Assets/april/37.jpg'
import april_38 from '../Assets/april/38.jpg'

import april_39 from '../Assets/april/39.jpg'
import april_40 from '../Assets/april/40.jpg'
import april_41 from '../Assets/april/41.jpg'
import april_42 from '../Assets/april/42.jpg'

import april_43 from '../Assets/april/43.jpg'
import april_44 from '../Assets/april/44.jpg'
import april_45 from '../Assets/april/45.jpg'

import april_46 from '../Assets/april/46.jpg'
import april_47 from '../Assets/april/47.jpg'
import april_48 from '../Assets/april/48.jpg'
import april_49 from '../Assets/april/49.jpg'
import april_50 from '../Assets/april/50.jpg'
import april_51 from '../Assets/april/51.jpg'
import april_52 from '../Assets/april/52.jpg'
import april_53 from '../Assets/april/53.jpg'
import april_54 from '../Assets/april/54.jpg'
import april_55 from '../Assets/april/55.jpg'
import april_56 from '../Assets/april/56.jpg'

import april_57 from '../Assets/april/57.jpg'
import april_58 from '../Assets/april/58.jpg'
import april_59 from '../Assets/april/59.jpg'
import april_60 from '../Assets/april/60.jpg'
import april_61 from '../Assets/april/61.jpg'
import april_62 from '../Assets/april/62.jpg'
import april_63 from '../Assets/april/63.jpg'
import april_64 from '../Assets/april/64.jpg'


const April = () => {
  const images = [
    { img: april_1 },
    { img: april_2 },
    { img: april_3 },
    { img: april_4 },
    { img: april_5 },
    { img: april_6 },
    { img: april_7 },
    { img: april_8 },
    { img: april_9 },
    { img: april_10 },

    { img: april_11 },
    { img: april_12 },
    { img: april_13 },
    { img: april_14 },
    { img: april_15 },
    { img: april_16 },
    { img: april_17 },
    { img: april_18 },
    { img: april_19 },
    { img: april_20 },

    { img: april_21 },
    { img: april_22 },
    { img: april_23 },
    { img: april_24 },
    { img: april_25 },
    { img: april_26 },
    { img: april_27 },
    { img: april_28 },
    { img: april_29 },
    { img: april_30 },

    { img: april_31 },
    { img: april_32 },
    { img: april_33},
    { img: april_34 },
    { img: april_35 },
    { img: april_36 },
    { img: april_37 },
    { img: april_38 },
    { img: april_39 },
    { img: april_40 },

    { img: april_41 },
    { img: april_42 },
    { img: april_43 },
    { img: april_44 },
    { img: april_45 },
    { img: april_46 },
    { img: april_47 },
    { img: april_48 },
    { img: april_49 },
    { img: april_50 },

    { img: april_51 },
    { img: april_52 },
    { img: april_53 },
    { img: april_54 },
    { img: april_55 },
    { img: april_56 },
    { img: april_57 },
    { img: april_58 },
    { img: april_59 },
    { img: april_60 },

    { img: april_61 },
    { img: april_62 },
    { img: april_63 },
    { img: april_64 },
,
  ];
  return (
    <div>
      <Header />
      <h1 className="heading">
        <u>April 2021</u>
      </h1>

      <div className="container-fluid">
        <div className="row mt-4">
          {images.map((item, index) => {
            return (
              <div className="item col-sm-6 col-md-3 mb-3">
                <a href={item.img} className="fancybox" data-fancybox="gallery1">
                  <img src={item.img} width="100%" height="100%" />
                </a>
              </div>
            );
          })}
        </div>
      </div>

      <div id="fb-root"></div>
      <Footer />
    </div>
  );
};

export default April;
