import React from "react";
import img from '../Assets/aaf-logo.png'


const Header = () => {
  return (
    <div>
      <nav className="navbar navbar-expand-lg bg-light">
        <div className="container-fluid">
          <a className="navbar-brand me-2" href="/">
            <img
              className="img-fluid aaf-logo"
              src={img}
              alt=""
              loading="lazy"
              style={{ marginTop: "-1px", height: "75px" }}
            />
          </a>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fas fa-bars"></i>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link" href="/">
                  <i className="fas fa-house-user"></i> Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/about">
                  <i className="fas fa-info-circle"></i> About Us
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.akankshaaradhyafoundation.com/covid-helpline/"
                  target="_blank"
                >
                  <span className="badge rounded-pill badge-notification bg-danger">
                    New
                  </span>
                  <i className="fas fa-viruses"></i> Covid Helpdesk
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href=""
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fas fa-chart-line"></i> Projects
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <a className="dropdown-item" href="/gurukul">
                      Gurukul
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/arogyam">
                      Arogyam
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/annapurna">
                      Annapurna
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/palna">
                      Palna
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="events"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="badge rounded-pill badge-notification bg-danger">
                    New
                  </span>
                  <i className="fas fa-regular fa-video"></i> Events
                </a>
                <ul className="dropdown-menu" aria-labelledby="events">
                  <li>
                    <a className="dropdown-item" href="/upcomingevent">
                      Upcoming Events
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/past-events">
                      Past Events
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/news">
                  <i className="far fa-newspaper"></i> News
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/gallery">
                  <i className="far fa-images"></i> Gallery
                </a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" href="/contact">
                  <i className="fas fa-envelope"></i> Contact Us
                </a>
              </li> */}
            </ul>
          </div>
        </div>

        <a
          className="btn btn-dark"
          style={{ width: "150px", height: "50px", fontSize: "medium" }}
          href="/donate"
        >
          <i className="fas fa-donate"></i>
          Donate
        </a>
      </nav>
    </div>
  );
};

export default Header;
