import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import news from '../Assets/1.jpg';
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css';
import { Fancybox } from "@fancyapps/fancybox";

const News = () => {
  return (
    <div>
      <Header />
      <h1 className="heading">
        <u>News & Media</u>
      </h1>

      <div className="container-fluid">
        <div className="row mt-4">
          <div className="item col-sm-6 col-md-3 mb-3">
            <a
              href={news}
              data-fancybox="gallery1" // This triggers Fancybox
              className="fancybox"
            >
              <img
                src={news}
                width="100%"
                height="100%"
                alt="News"
              />
            </a>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default News;
