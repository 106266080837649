import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import banner_4 from '../Assets/theater/Banner-4 -color Logo-Done.png'
import banner from '../Assets/theater/Home Page-Banner (Landscape).jpg'
const TheaterList = () => {
  return (
    <div>
      <Header />
      <div className="container-fluid">
        <h1 className="gallery_heading">Theater List</h1>
      </div>
      <div className="container-fluid">
        <div className="row mt-4">
          <div className="card col-3 gallery-card border-0">
            <img
              src={banner_4}
              className="card-img-top"
              alt="..."
            />
            <div className="card-body">
              <h5 className="card-title">EK Bakwaas Love Story</h5>
              <a
                href="/theater_ek_bakwaas_love_story"
                className=" btn btn-primary "
              >
                Full Album
              </a>
            </div>
          </div>
          <div className="card col-3 gallery-card border-0">
            <img
              src={banner}
              className="card-img-top"
              alt="... "
            />
            <div className="card-body">
              <h5 className="card-title">Oh-Fish Office</h5>
              <a href="/theater_ohfish_office" className="btn btn-primary ">
                Full Album
              </a>
            </div>
          </div>
        </div>
      </div>
      <div id="fb-root"></div>
      <Footer />
    </div>
  );
};

export default TheaterList;
