import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import a from "../Assets/theater/oh-fish office/1D6A5339.JPG";
import b from "../Assets/theater/oh-fish office/1D6A5351.JPG";
import c from "../Assets/theater/oh-fish office/1D6A5355.JPG";
import d from "../Assets/theater/oh-fish office/1D6A5367.JPG";
import e from "../Assets/theater/oh-fish office/1D6A5369.JPG";
import f from "../Assets/theater/oh-fish office/1D6A5371.JPG";
import g from "../Assets/theater/oh-fish office/1D6A5376.JPG";
import h from "../Assets/theater/oh-fish office/1D6A5394.JPG";
import i from "../Assets/theater/oh-fish office/1D6A5396.JPG";
import j from "../Assets/theater/oh-fish office/1D6A5399.JPG";
import k from "../Assets/theater/oh-fish office/1D6A5404.JPG";
import l from "../Assets/theater/oh-fish office/1D6A5408.JPG";
import m from "../Assets/theater/oh-fish office/1D6A5412.JPG";
import n from "../Assets/theater/oh-fish office/1D6A5415.JPG";
import o from "../Assets/theater/oh-fish office/1D6A5420.JPG";
import p from "../Assets/theater/oh-fish office/1D6A5423.JPG";
import q from "../Assets/theater/oh-fish office/1D6A5429.JPG";
import r from "../Assets/theater/oh-fish office/1D6A5432.JPG";
import s from "../Assets/theater/oh-fish office/1D6A5443.JPG";
import t from "../Assets/theater/oh-fish office/1D6A5449.JPG";
import u from "../Assets/theater/oh-fish office/1D6A5455.JPG";
import v from "../Assets/theater/oh-fish office/1D6A5459.JPG";
import w from "../Assets/theater/oh-fish office/1D6A5461.JPG";
import y from "../Assets/theater/oh-fish office/1D6A5471.JPG";
import z from "../Assets/theater/oh-fish office/1D6A5475.JPG";

import aa from "../Assets/theater/oh-fish office/1D6A5479.JPG";
import ab from "../Assets/theater/oh-fish office/1D6A5483.JPG";
import ac from "../Assets/theater/oh-fish office/1D6A5493.JPG";
import ad from "../Assets/theater/oh-fish office/1D6A5497.JPG";
import ae from "../Assets/theater/oh-fish office/1D6A5500.JPG";
import af from "../Assets/theater/oh-fish office/1D6A5511.JPG";
import ag from "../Assets/theater/oh-fish office/1D6A5513.JPG";
import ah from "../Assets/theater/oh-fish office/1D6A5517.JPG";
import ai from "../Assets/theater/oh-fish office/1D6A5527.JPG";
import ak from "../Assets/theater/oh-fish office/1D6A5529.JPG";
import al from "../Assets/theater/oh-fish office/1D6A5533.JPG";
import am from "../Assets/theater/oh-fish office/1D6A5536.JPG";
import an from "../Assets/theater/oh-fish office/1D6A5542.JPG";
import ao from "../Assets/theater/oh-fish office/1D6A5546.JPG";
import ap from "../Assets/theater/oh-fish office/1D6A5548.JPG";
import aq from "../Assets/theater/oh-fish office/1D6A5554.JPG";
import ar from "../Assets/theater/oh-fish office/1D6A5556.JPG";
import as from "../Assets/theater/oh-fish office/1D6A5559.JPG";
import at from "../Assets/theater/oh-fish office/1D6A5561.JPG";
import au from "../Assets/theater/oh-fish office/1D6A5563.JPG";
import av from "../Assets/theater/oh-fish office/1D6A5569.JPG";
import aw from "../Assets/theater/oh-fish office/1D6A5572.JPG";
import ax from "../Assets/theater/oh-fish office/1D6A5579.JPG";
import ay from "../Assets/theater/oh-fish office/1D6A5585.JPG";
import az from "../Assets/theater/oh-fish office/1D6A5588.JPG";
import img1 from "../Assets/theater/oh-fish office/1D6A5590.JPG";
import img2 from "../Assets/theater/oh-fish office/1D6A5596.JPG";
import img3 from "../Assets/theater/oh-fish office/1D6A5598.JPG";
import img4 from "../Assets/theater/oh-fish office/1D6A5605.JPG";
import img5 from "../Assets/theater/oh-fish office/1D6A5612.JPG";
import img6 from "../Assets/theater/oh-fish office/1D6A5614.JPG";
import img7 from "../Assets/theater/oh-fish office/1D6A5615.JPG";
import img8 from "../Assets/theater/oh-fish office/1D6A5617.JPG";
import img9 from "../Assets/theater/oh-fish office/1D6A5624.JPG";
import img10 from "../Assets/theater/oh-fish office/1D6A5632.JPG";
import img11 from "../Assets/theater/oh-fish office/1D6A5636.JPG";
import img12 from "../Assets/theater/oh-fish office/1D6A5647.JPG";
import img13 from "../Assets/theater/oh-fish office/1D6A5668.JPG";
import img14 from "../Assets/theater/oh-fish office/1D6A5670.JPG";
import img15 from "../Assets/theater/oh-fish office/1D6A5678.JPG";
import img16 from "../Assets/theater/oh-fish office/1D6A5683.JPG";
import img17 from "../Assets/theater/oh-fish office/1D6A5685.JPG";
import img18 from "../Assets/theater/oh-fish office/1D6A5693.JPG";
import img19 from "../Assets/theater/oh-fish office/1D6A5696.JPG";
import img20 from "../Assets/theater/oh-fish office/1D6A5699.JPG";
import img21 from "../Assets/theater/oh-fish office/1D6A5701.JPG";
import img22 from "../Assets/theater/oh-fish office/1D6A5702.JPG";
import img23 from "../Assets/theater/oh-fish office/1D6A5704.JPG";
import img24 from "../Assets/theater/oh-fish office/1D6A5711.JPG";
import img25 from "../Assets/theater/oh-fish office/1D6A5714.JPG";
import img26 from "../Assets/theater/oh-fish office/1D6A5718.JPG";
import img27 from "../Assets/theater/oh-fish office/1D6A5723.JPG";
import img28 from "../Assets/theater/oh-fish office/1D6A5728.JPG";
import img29 from "../Assets/theater/oh-fish office/1D6A5734.JPG";
import img30 from "../Assets/theater/oh-fish office/1D6A5741.JPG";
import img31 from "../Assets/theater/oh-fish office/1D6A5744.JPG";
import img32 from "../Assets/theater/oh-fish office/1D6A5747.JPG";
import img33 from "../Assets/theater/oh-fish office/1D6A5753.JPG";
import img34 from "../Assets/theater/oh-fish office/1D6A5756.JPG";
import img35 from "../Assets/theater/oh-fish office/1D6A5760.JPG";
import img36 from "../Assets/theater/oh-fish office/1D6A5774.JPG";
import img37 from "../Assets/theater/oh-fish office/1D6A5778.JPG";
import img38 from "../Assets/theater/oh-fish office/1D6A5791.JPG";
import img39 from "../Assets/theater/oh-fish office/1D6A5804.JPG";
import img40 from "../Assets/theater/oh-fish office/1D6A5806.JPG";
import img41 from "../Assets/theater/oh-fish office/1D6A5810.JPG";
import img42 from "../Assets/theater/oh-fish office/1D6A5813.JPG";
import img43 from "../Assets/theater/oh-fish office/1D6A5817.JPG";
import img44 from "../Assets/theater/oh-fish office/1D6A5823.JPG";
import img45 from "../Assets/theater/oh-fish office/1D6A5828.JPG";
import img46 from "../Assets/theater/oh-fish office/1D6A5843.JPG";
import img47 from "../Assets/theater/oh-fish office/1D6A5849.JPG";
import img48 from "../Assets/theater/oh-fish office/1D6A5853.JPG";
import img49 from "../Assets/theater/oh-fish office/1D6A5858.JPG";
import img50 from "../Assets/theater/oh-fish office/1D6A5859.JPG";
import img51 from "../Assets/theater/oh-fish office/1D6A5876.JPG";
import img52 from "../Assets/theater/oh-fish office/1D6A5885.JPG";
import img53 from "../Assets/theater/oh-fish office/1D6A5906.JPG";
import img54 from "../Assets/theater/oh-fish office/1D6A5909.JPG";
import img55 from "../Assets/theater/oh-fish office/1D6A5922.JPG";
import img56 from "../Assets/theater/oh-fish office/1D6A5924.JPG";
import img57 from "../Assets/theater/oh-fish office/1D6A5927.JPG";
import img58 from "../Assets/theater/oh-fish office/1D6A5933.JPG";
import img59 from "../Assets/theater/oh-fish office/1D6A5942.JPG";
import img60 from "../Assets/theater/oh-fish office/1D6A5949.JPG";
import img61 from "../Assets/theater/oh-fish office/1D6A5952.JPG";
import img62 from "../Assets/theater/oh-fish office/1D6A5953.JPG";
import img63 from "../Assets/theater/oh-fish office/1D6A5958.JPG";
import img64 from "../Assets/theater/oh-fish office/1D6A5959.JPG";
import img65 from "../Assets/theater/oh-fish office/1D6A5970.JPG";
import img66 from "../Assets/theater/oh-fish office/1D6A5977.JPG";
import img67 from "../Assets/theater/oh-fish office/1D6A5980.JPG";
import img68 from "../Assets/theater/oh-fish office/1D6A5983.JPG";
import img69 from "../Assets/theater/oh-fish office/1D6A5989.JPG";
import img70 from "../Assets/theater/oh-fish office/1D6A5996.JPG";
import img71 from "../Assets/theater/oh-fish office/1D6A5999.JPG";
import img72 from "../Assets/theater/oh-fish office/1D6A6004.JPG";
import img73 from "../Assets/theater/oh-fish office/1D6A6014.JPG";
import img74 from "../Assets/theater/oh-fish office/1D6A6058.JPG";
import img75 from "../Assets/theater/oh-fish office/1D6A6068.JPG";

const Theater_Ohfish_Office = () => {
  const images = [
    a,
    b,
    c,
    d,
    e,
    f,
    g,
    h,
    i,
    j,
    k,
    l,
    m,
    n,
    o,
    p,
    q,
    r,
    s,
    t,
    u,
    v,
    w,
    y,
    z,
    aa,
    ab,
    ac,
    ad,
    ae,
    af,
    ag,
    ah,
    ai,
    ak,
    al,
    am,
    an,
    ao,
    ap,
    aq,
    ar,
    as,
    at,
    au,
    av,
    aw,
    ax,
    ay,
    az,
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
    img20,
    img21,
    img22,
    img23,
    img24,
    img25,
    img26,
    img27,
    img28,
    img29,
    img30,
    img31,
    img32,
    img33,
    img34,
    img35,
    img36,
    img37,
    img38,
    img39,
    img40,
    img41,
    img42,
    img43,
    img44,
    img45,
    img46,
    img47,
    img48,
    img49,
    img50,
    img51,
    img52,
    img53,
    img54,
    img55,
    img56,
    img57,
    img58,
    img59,
    img60,
    img61,
    img62,
    img63,
    img64,
    img65,
    img66,
    img67,
    img68,
    img69,
    img70,
    img71,
    img72,
    img73,
    img74,
    img75,
  ];
  return (
    <div>
      <Header />
      <h1 className="heading">
        <u>Oh-Fish Office</u>
      </h1>

      <div className="container-fluid">
        <div className="row mt-4">
          {images.map((image, index) => (
            <div className="item col-sm-6 col-md-3 mb-3" key={index}>
              <a href={image} className="fancybox" data-fancybox="gallery1">
                <img
                  src={image}
                  width="100%"
                  className="img-fluid height_set"
                  alt={`Slide ${index + 1}`}
                />
              </a>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};


export default Theater_Ohfish_Office;
