import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import june_1 from '../Assets/june/1.jpg'
import june_2 from '../Assets/june/2.jpg'
import june_3 from '../Assets/june/3.jpg'
import june_4 from '../Assets/june/4.jpg'
import june_5 from '../Assets/june/5.jpg'
import june_6 from '../Assets/june/6.jpg'
import june_7 from '../Assets/june/7.jpg'
import june_8 from '../Assets/june/8.jpg'
import june_9 from '../Assets/june/9.jpg'
import june_10 from '../Assets/june/10.jpg'
import june_11 from '../Assets/june/11.jpg'

import june_12 from '../Assets/june/12.jpg'
import june_13 from '../Assets/june/13.jpg'
import june_14 from '../Assets/june/14.jpg'
import june_15 from '../Assets/june/15.jpg'


import june_16 from '../Assets/june/16.jpg'
import june_17 from '../Assets/june/17.jpg'
import june_18 from '../Assets/june/18.jpg'

import june_19 from '../Assets/june/19.jpg'
import june_20 from '../Assets/june/20.jpg'
import june_21 from '../Assets/june/21.jpg'
import june_22 from '../Assets/june/22.jpg'

import june_23 from '../Assets/june/23.jpg'
import june_24 from '../Assets/june/24.jpg'
import june_25 from '../Assets/june/25.jpg'

import june_26 from '../Assets/june/26.jpg'
import june_27 from '../Assets/june/27.jpg'
import june_28 from '../Assets/june/28.jpg'

import june_29 from '../Assets/june/29.jpg'
import june_30 from '../Assets/june/30.jpg'
import june_31 from '../Assets/june/31.jpg'

import june_32 from '../Assets/june/32.jpg'
import june_33 from '../Assets/june/33.jpg'
import june_34 from '../Assets/june/34.jpg'

import june_35 from '../Assets/june/35.jpg'
import june_36 from '../Assets/june/36.jpg'
import june_37 from '../Assets/june/37.jpg'
import june_38 from '../Assets/june/38.jpg'



const June = () => {
  const images = [
    { img: june_1 },
    { img: june_2 },
    { img: june_3 },
    { img: june_4 },
    { img: june_5 },
    { img: june_6 },
    { img: june_7 },
    { img: june_8 },
    { img: june_9 },
    { img: june_10 },

    { img: june_11 },
    { img: june_12 },
    { img: june_13 },
    { img: june_14 },
    { img: june_15 },
    { img: june_16 },
    { img: june_17 },
    { img: june_18 },
    { img: june_19 },
    { img: june_20 },

    { img: june_21 },
    { img: june_22 },
    { img: june_23 },
    { img: june_24 },
    { img: june_25 },
    { img: june_26 },
    { img: june_27 },
    { img: june_28 },
    { img: june_29 },
    { img: june_30 },

    { img: june_31 },
    { img: june_32 },
    { img: june_33},
    { img: june_34 },
    { img: june_35 },
    { img: june_36 },
    { img: june_37 },
    { img: june_38 },



  ];
  return (
    <div>
      <Header />
      <h1 className="heading">
        <u>June 2021</u>
      </h1>

      <div className="container-fluid">
        <div className="row mt-4">
          {images.map((item, index) => {
            return (
              <div className="item col-sm-6 col-md-3 mb-3">
                <a href={item.img} className="fancybox" data-fancybox="gallery1">
                  <img src={item.img} width="100%" height="100%" />
                </a>
              </div>
            );
          })}
        </div>
      </div>

      <div id="fb-root"></div>
      <Footer />
    </div>
  );
};

export default June;
