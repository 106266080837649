import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import january_3 from '../Assets/january/3.jpg'
import february_5 from '../Assets/february/5.jpg'
import march_28 from '../Assets/march/28.jpg'
import april_49 from '../Assets/april/49.jpg'
import may_9 from '../Assets/may/9.jpg'
import june_9 from '../Assets/june/9.jpg'
import july_9 from '../Assets/july/9.jpg'
import august_9 from '../Assets/august/9.jpg'
import april_19 from '../Assets/april/19.jpg'
import april_26 from '../Assets/april/26.jpg'
import april_12 from '../Assets/april/12.jpg'

const RighttoFood = () => {
  return (
    <div>
      <Header />
      <div className="container-fluid">
        <h1 className="gallery_heading">Right to Food</h1>
      </div>
      <div className="container-fluid">
        <div className="row mt-4">
          <div className="card col-3 gallery-card border-0">
            <img
              src={january_3}
              className="card-img-top"
              alt="..."
            />
            <div className="card-body">
              <h5 className="card-title">January 2021</h5>
              <a href="/january" className=" btn btn-primary ">
                Full Album
              </a>
            </div>
          </div>
          <div className="card col-3 gallery-card border-0">
            <img
              src={february_5}
              className="card-img-top "
              alt="... "
            />
            <div className="card-body">
              <h5 className="card-title">February 2021</h5>
              <a href="/february " className="btn btn-primary ">
                Full Album
              </a>
            </div>
          </div>
          <div className="card col-3 gallery-card border-0">
            <img
              src={march_28}
              className="card-img-top "
              alt="... "
            />
            <div className="card-body ">
              <h5 className="card-title ">March 2021</h5>
              <a href="/march" className="btn btn-primary ">
                Full Album
              </a>
            </div>
          </div>
          <div className="card col-3 gallery-card border-0">
            <img
              src={april_49}
              className="card-img-top "
              alt="... "
            />
            <div className="card-body ">
              <h5 className="card-title ">April 2021</h5>
              <a href="/april" className="btn btn-primary ">
                Full Album
              </a>
            </div>
          </div>
          <div className="card col-3 gallery-card border-0">
            <img
              src={may_9}
              className="card-img-top "
              alt="... "
            />
            <div className="card-body ">
              <h5 className="card-title ">May 2021</h5>
              <a href="/may" className="btn btn-primary ">
                Full Album
              </a>
            </div>
          </div>
          <div className="card col-3 gallery-card border-0">
            <img
              src={june_9}
              className="card-img-top "
              alt="... "
            />
            <div className="card-body ">
              <h5 className="card-title ">June 2021</h5>
              <a href="/june" className="btn btn-primary ">
                Full Album
              </a>
            </div>
          </div>
          <div className="card col-3 gallery-card border-0">
            <img
              src={july_9}
              className="card-img-top "
              alt="... "
            />
            <div className="card-body ">
              <h5 className="card-title ">July 2021</h5>
              <a href="/july" className="btn btn-primary ">
                Full Album
              </a>
            </div>
          </div>
          <div className="card col-3 gallery-card border-0">
            <img
              src={august_9}
              className="card-img-top "
              alt="... "
            />
            <div className="card-body ">
              <h5 className="card-title ">August 2021</h5>
              <a href="/august" className="btn btn-primary ">
                Full Album
              </a>
            </div>
          </div>
          <div className="card col-3 gallery-card border-0">
            <img
              src={april_19}
              className="card-img-top "
              alt="... "
            />
            <div className="card-body ">
              <h5 className="card-title ">September 2021</h5>
              <a href="/september" className="btn btn-primary ">
                Full Album
              </a>
            </div>
          </div>
          <div className="card col-3 gallery-card border-0">
            <img
              src={april_26}
              className="card-img-top "
              alt="... "
            />
            <div className="card-body ">
              <h5 className="card-title ">October 2021</h5>
              <a href="/october" className="btn btn-primary ">
                Full Album
              </a>
            </div>
          </div>
          <div className="card col-3 gallery-card border-0">
            <img
              src={april_12}
              className="card-img-top "
              alt="... "
            />
            <div className="card-body ">
              <h5 className="card-title ">November 2021</h5>
              <a href="/november" className="btn btn-primary ">
                Full Album
              </a>
            </div>
          </div>
        </div>
      </div>

      <div id="fb-root"></div>
      <Footer />
    </div>
  );
};

export default RighttoFood;
