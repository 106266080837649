import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import image from '../Assets/AAF-Donation.jpeg'

const Donate = () => {
  return (
    <div>
      <Header />
      <div className="container donateContainer">
        <div className="row donateRow">
          <div className="container donatebtnContainer col-xl-6 col-md-6 col-sm-12">
            <button
              type="button"
              className="btn donate-btn"
              data-bs-toggle="modal"
              data-bs-target="#donateCashModal"
            >
              DONATE CASH
            </button>

            <div
              className="modal fade"
              id="donateCashModal"
              tabIndex="-1"
              aria-labelledby="donateCashModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-sm">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="donateCashModalLabel">
                      Donate Cash
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <img
                      className="img-fluid"
                      src={image}
                      alt="Donation"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="container donatebtnContainer col-xl-6 col-md-6 col-sm-12">
            <button
              type="button"
              className="btn donate-btn"
              data-bs-toggle="modal"
              data-bs-target="#donateItemModal"
            >
              DONATE ITEM
            </button>

            <div
              className="modal fade"
              id="donateItemModal"
              tabIndex="-1"
              aria-labelledby="donateItemModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-sm">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="donateItemModalLabel">
                      Fill details to <strong>DONATE</strong>
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <form className="donateForm">
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingName"
                          placeholder="Name"
                        />
                        <label htmlFor="floatingName">Name</label>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          type="email"
                          className="form-control"
                          id="floatingEmail"
                          placeholder="name@example.com"
                        />
                        <label htmlFor="floatingEmail">Email address</label>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          type="tel"
                          className="form-control"
                          id="floatingPhone"
                          placeholder="Phone number"
                        />
                        <label htmlFor="floatingPhone">Phone number</label>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingAddress"
                          placeholder="Address"
                        />
                        <label htmlFor="floatingAddress">Address</label>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingItemName"
                          placeholder="Item Name"
                        />
                        <label htmlFor="floatingItemName">Item Name</label>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          type="number"
                          className="form-control"
                          id="floatingQuantity"
                          placeholder="Quantity"
                        />
                        <label htmlFor="floatingQuantity">Quantity</label>
                      </div>
                      <div className="button_submit">
                        <button type="submit" className="submit_button">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      <div id="fb-root"></div>
      <Footer />
    </div>
  );
};

export default Donate;
