import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import july_1 from "../Assets/july/1.jpg";
import july_2 from "../Assets/july/2.jpg";
import july_3 from "../Assets/july/3.jpg";
import july_4 from "../Assets/july/4.jpg";
import july_5 from "../Assets/july/5.jpg";
import july_6 from "../Assets/july/6.jpg";
import july_7 from "../Assets/july/7.jpg";
import july_8 from "../Assets/july/8.jpg";
import july_9 from "../Assets/july/9.jpg";
import july_10 from "../Assets/july/10.jpg";
import july_11 from "../Assets/july/11.jpg";

import july_12 from "../Assets/july/12.jpg";
import july_13 from "../Assets/july/13.jpg";
import july_14 from "../Assets/july/14.jpg";
import july_15 from "../Assets/july/15.jpg";

import july_16 from "../Assets/july/16.jpg";
import july_17 from "../Assets/july/17.jpg";

const July = () => {
  const images = [
    { img: july_1 },
    { img: july_2 },
    { img: july_3 },
    { img: july_4 },
    { img: july_5 },
    { img: july_6 },
    { img: july_7 },
    { img: july_8 },
    { img: july_9 },
    { img: july_10 },

    { img: july_11 },
    { img: july_12 },
    { img: july_13 },
    { img: july_14 },
    { img: july_15 },
    { img: july_16 },
    { img: july_17 },
  ];
  return (
    <div>
      <Header />
      <h1 className="heading">
        <u>July 2021</u>
      </h1>

      <div className="container-fluid">
        <div className="row mt-4">
          {images.map((item, index) => {
            return (
              <div className="item col-sm-6 col-md-3 mb-3">
                <a href={item.img} className="fancybox" data-fancybox="gallery1">
                  <img src={item.img} width="100%" height="100%" />
                </a>
              </div>
            );
          })}
        </div>
      </div>

      <div id="fb-root"></div>
      <Footer />
    </div>
  );
};

export default July;
