import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import annapurna from "../Assets/Annapurna-image.jpeg";
import logo from '../Assets/annapurna-logo.png'

const Annapurna = () => {
  return (
    <div>
      <Header />
      <div className="container">
        <div className="row text-center">
          <div className="col-xl-2 col-md-2 col-sm-12">
            <a className="navbar-brand me-2" href="/annapurna">
              <img
                className="img-fluid annapurna-logo"
                src={logo}
                alt=""
                loading="lazy"
                style={{ marginTop: "-1px", height: "75px" }}
              />
            </a>
          </div>
          <div className="col-xl-10 col-md-10 col-sm-12">
            <h1 className="heading">
              <u>The Annapurna Initiative</u>
            </h1>
          </div>
          <div className="gap-1"></div>
          <h2 className="heading">
            "Annapurna aims at setting up free food distribution centers across
            the globe."
          </h2>
        </div>
        <div className="gap-1"></div>
        <div className="container">
          <div className="row">
            <div className="container col-xl-6 col-md-12 col-sm-12">
              <div className="annapurna_image">
                <img
                  className="img-fluid"
                  src={annapurna}
                  alt=""
                />
              </div>
            </div>
            <div className="container col-xl-6 col-md-12 col-sm-12">
              <div className="annapurna_paragraph">
                <p align="justify">
                  Everyone has the right to a standard of living adequate for
                  the health and well-being of himself and of his family,
                  including food, clothing, housing and medical care and
                  necessary social services, and the right to security in the
                  event of unemployment, sickness, disability, widowhood, old
                  age or other lack of livelihood in circumstances beyond his
                  control.
                </p>
                <p align="justify">
                  <strong>Akanksha Aradhya Foundation</strong> aims at
                  contributing to this cause not only for human beings but also
                  all living beings. Annapurna is an initiative to feed the
                  hungry world with basic food through drives, education and
                  encouraging people to eat less, stop food wastage and creating
                  food distribution network wherever required.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="fb-root"></div>
      <Footer />
    </div>
  );
};

export default Annapurna;
