import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import february_1 from "../Assets/february/1.jpg";
import february_2 from "../Assets/february/2.jpg";
import february_3 from "../Assets/february/3.jpg";
import february_4 from "../Assets/february/4.jpg";
import february_5 from "../Assets/february/5.jpg";
import february_6 from "../Assets/february/6.jpg";
import february_7 from "../Assets/february/7.jpg";
import february_8 from "../Assets/february/8.jpg";
import february_9 from "../Assets/february/9.jpg";
import february_10 from "../Assets/february/10.jpg";

const Febuary = () => {
  const images = [
    { img: february_1 },
    { img: february_2 },
    { img: february_3 },
    { img: february_4 },
    { img: february_5 },
    { img: february_6 },
    { img: february_7 },
    { img: february_8 },
    { img: february_9 },
    { img: february_10 },
  ];
  return (
    <div>
      <Header />
      <h1 className="heading">
        <u>February 2021</u>
      </h1>

      <div className="container-fluid">
        <div className="row mt-4">
          {images.map((item, index) => {
            return (
              <div className="item col-sm-6 col-md-3 mb-3">
                <a href={item.img} className="fancybox" data-fancybox="gallery1">
                  <img src={item.img} width="100%" height="100%" />
                </a>
              </div>
            );
          })}
        </div>
      </div>

      <div id="fb-root"></div>
      <Footer />
    </div>
  );
};

export default Febuary;
