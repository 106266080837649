import React from 'react'
import Header from './Header'
import Footer from './Footer'
import bakbass from '../Assets/Ek Bakwas love story @...2.jpg'

const PastEvent = () => {
  return (
    <div>
        <Header/>
        <div className="container mt-5">
        <div className="row">
            <div className="col-md-4 col-sm-12">
                <div className="cards">
                    <div className="cards-head text-center shadow">
                        <img src={bakbass} className="img-fluid rounded" style={{height: "200px"}} alt=""/>
                    </div>
                    <div style={{height: "30px"}}></div>
                    <div className="cards-body">
                        <h3>Ek Bakwaas Love Story @...</h3>
                        <a href="/ek_bakbaas_story" className="btn btn-success w-100">Know More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
        <Footer/>
    </div>
  )
}

export default PastEvent